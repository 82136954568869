<template>
  <div>
    <!--  ประเภท  -->
    <b-form-group
        :label="'ประเภท'"
        class="m-0"
        label-cols-lg="3"
        label-cols-sm="4"
    >
      <b-form-radio-group
          id="type"
          v-model="form.bankUseType"
          button-variant="outline-primary"
          buttons
          name="type"
          size="sm"
      >
        <b-form-radio :value="0">
          {{ $t('bank_accounts.types.deposit_withdraw') }}
        </b-form-radio>
        <b-form-radio :value="1">
          {{ $t('bank_accounts.types.deposit') }}
        </b-form-radio>
        <b-form-radio :value="2">
          {{ $t('bank_accounts.types.withdraw') }}
        </b-form-radio>
        <b-form-radio :value="3">
          {{ $t('bank_accounts.types.hold') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <!--  สำหรับยอดฝากตั้งแต่  -->
    <ValidationProvider
        v-if="form.bankUseType === BankUseTypeEnum.BOTH || form.bankUseType === BankUseTypeEnum.DEPOSIT"
        v-slot="{ errors }"
        :name="$t('bank_accounts.bank')"
        rules="required"
    >
      <b-form-group
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="for-min-deposit"
      >
        <template #label>
          สำหรับลูกค้าที่มียอดฝาก
          <span
              v-b-tooltip.hover
              title="แสดงบัญชีนี้สำหรับลูกค้าที่มีประวัติฝากรวมมากกว่าที่กำหนด">
              <b-icon-info-circle />
            </span>
        </template>
        <b-input-group append="บาท ขึ้นไป">
          <b-input-group-prepend is-text>
            <b-form-checkbox
                v-model="form.isEnableForMinDeposit"
                name="isEnableForMinDeposit"
                switch
            />
          </b-input-group-prepend>
          <b-input
              id="for-min-deposit"
              v-model.number="form.minTotalDeposit"
              :disabled="!form.isEnableForMinDeposit"
              :state="errors[0] ? false : null"
              type="number"
          ></b-input>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <!--  ค่าธรรมเนียม  -->
    <ValidationProvider
        v-if="form.bankUseType === BankUseTypeEnum.BOTH || form.bankUseType === BankUseTypeEnum.DEPOSIT"
        v-slot="{ errors }"
        :name="$t('bank_accounts.bank')"
        rules="required|between:0,100"
    >
        <b-form-group
          label="ค่าธรรมเนียม"
          label-cols-lg="3"
          label-cols-sm="4"
          label-for="multiplier-fee"
      >
        <b-input-group append="%">
          <b-input
              id="multiplier-fee"
              v-model.number="form.multiplierFee"
              :state="errors[0] ? false : null"
              step="0.01"
              type="number"
          ></b-input>
        </b-input-group>
      </b-form-group>
    </ValidationProvider>

    <!--  เวลาแสดงบัญชี  -->
    <b-form-group
        v-if="[BankUseTypeEnum.BOTH, BankUseTypeEnum.DEPOSIT].includes(form.bankUseType) && !isAgent"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="showing_time"
    >
      <b-form-checkbox v-model="form.isSetDisplayTime" :switch="true">
        แสดงบัญชีตามเวลา
        <span
            v-b-tooltip.hover
            title="แสดงบัญชีนี้ที่หน้าเว็บ เมื่อถึงเวลาที่กำหนด">
              <b-icon-info-circle />
            </span>
      </b-form-checkbox>

      <b-row v-if="form.isSetDisplayTime" class="mt-3">
        <b-col>
          <ValidationProvider
              v-slot="{ errors }"
              :rules="`required|min:5`"
              name="displayFrom"
          >
            <b-form-group label="เริ่มเวลา">
              <b-form-timepicker
                  v-model="form.displayFrom"
                  :hour12="false"
                  :state="errors[0] ? false : null"
                  locale="en" />
            </b-form-group>
          </ValidationProvider>
        </b-col>

        <b-col>
          <ValidationProvider
              v-slot="{ errors }"
              :rules="`required|min:5`"
              name="displayTo"
          >
            <b-form-group label="ถึงเวลา">
              <b-form-timepicker
                  v-model="form.displayTo"
                  :hour12="false"
                  :state="errors[0] ? false : null"
                  locale="en" />
            </b-form-group>
          </ValidationProvider>
        </b-col>
      </b-row>
    </b-form-group>

    <!--  OTP ถอน  -->
    <b-form-group
        v-if="[BankUseTypeEnum.BOTH, BankUseTypeEnum.WITHDRAW].includes(form.bankUseType)"
        label-cols-lg="3"
        label-cols-sm="4"
        label-for="isRequiredOtp"
    >
      <b-input-group>
        <b-form-checkbox
            v-model="form.isRequiredOtp"
            name="isRequiredOtp"
            switch
        >
          เปิดใช้งาน OTP ถอน
          <span
              v-b-tooltip.hover.html
              title='ส่ง OTP สำหรับถอนเข้าไลน์กลุ่ม (ตั้งค่าได้ที่เมนู <a href="/line-notifications"><b><u>แจ้งเตือนไลน์</u></b>)'>
              <b-icon-info-circle />
          </span>
        </b-form-checkbox>
      </b-input-group>
    </b-form-group>

    <!--  สถานะ  -->
    <b-form-group
        class="m-0"
        label-cols-lg="3"
        label-cols-sm="4"
    >
      <b-form-checkbox
          id="status"
          v-model="form.isEnable"
          name="status"
          switch
      >
        เปิดใช้งาน
        <span v-if="form.bankUseType === BankUseTypeEnum.BOTH || form.bankUseType === BankUseTypeEnum.DEPOSIT">
          (แสดงหน้าเว็บ)
        </span>
      </b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import Vue from 'vue';
import {mapGetters} from 'vuex';
import {BankUseTypeEnum} from '@src/constants/bank';

export default Vue.extend({
  name: 'BankSettingForm',
  props: {
    bankAccountForm: {
      type: Object,
      default: () => ({
        bankUseType: 0,
        minTotalDeposit: 0,
        multiplierFee: 0,
        displayFrom: '',
        displayTo: '',
        isEnable: true,
        isRequiredOtp: false,
      })
    }
  },
  data() {
    return {
      form: {}
    }
  },
  computed: {
    ...mapGetters(['isAgent']),
    BankUseTypeEnum() {
      return BankUseTypeEnum
    }
  },
  watch: {
    form: {
      handler(val) {
        this.$emit('update', val)
      },
      deep: true
    }
  },
  created() {
    this.form = {
      bankUseType: +this.bankAccountForm.bankUseType,
      multiplierFee: +this.bankAccountForm.multiplierFee,
      displayFrom: this.bankAccountForm.displayFrom || '',
      displayTo: this.bankAccountForm.displayTo || '',
      isEnable: this.bankAccountForm.isEnable,
      isRequiredOtp: this.bankAccountForm.isRequiredOtp,
      minTotalDeposit: +this.bankAccountForm.minTotalDeposit,
      isEnableForMinDeposit: +this.bankAccountForm.minTotalDeposit > 0,
      isSetDisplayTime: !!(this.bankAccountForm.displayFrom && this.bankAccountForm.displayTo)
    }
  }
})
</script>

<style scoped>

</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"m-0",attrs:{"label":'ประเภท',"label-cols-lg":"3","label-cols-sm":"4"}},[_c('b-form-radio-group',{attrs:{"id":"type","button-variant":"outline-primary","buttons":"","name":"type","size":"sm"},model:{value:(_vm.form.bankUseType),callback:function ($$v) {_vm.$set(_vm.form, "bankUseType", $$v)},expression:"form.bankUseType"}},[_c('b-form-radio',{attrs:{"value":0}},[_vm._v(" "+_vm._s(_vm.$t('bank_accounts.types.deposit_withdraw'))+" ")]),_c('b-form-radio',{attrs:{"value":1}},[_vm._v(" "+_vm._s(_vm.$t('bank_accounts.types.deposit'))+" ")]),_c('b-form-radio',{attrs:{"value":2}},[_vm._v(" "+_vm._s(_vm.$t('bank_accounts.types.withdraw'))+" ")]),_c('b-form-radio',{attrs:{"value":3}},[_vm._v(" "+_vm._s(_vm.$t('bank_accounts.types.hold'))+" ")])],1)],1),(_vm.form.bankUseType === _vm.BankUseTypeEnum.BOTH || _vm.form.bankUseType === _vm.BankUseTypeEnum.DEPOSIT)?_c('ValidationProvider',{attrs:{"name":_vm.$t('bank_accounts.bank'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-cols-lg":"3","label-cols-sm":"4","label-for":"for-min-deposit"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" สำหรับลูกค้าที่มียอดฝาก "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"แสดงบัญชีนี้สำหรับลูกค้าที่มีประวัติฝากรวมมากกว่าที่กำหนด"}},[_c('b-icon-info-circle')],1)]},proxy:true}],null,true)},[_c('b-input-group',{attrs:{"append":"บาท ขึ้นไป"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('b-form-checkbox',{attrs:{"name":"isEnableForMinDeposit","switch":""},model:{value:(_vm.form.isEnableForMinDeposit),callback:function ($$v) {_vm.$set(_vm.form, "isEnableForMinDeposit", $$v)},expression:"form.isEnableForMinDeposit"}})],1),_c('b-input',{attrs:{"id":"for-min-deposit","disabled":!_vm.form.isEnableForMinDeposit,"state":errors[0] ? false : null,"type":"number"},model:{value:(_vm.form.minTotalDeposit),callback:function ($$v) {_vm.$set(_vm.form, "minTotalDeposit", _vm._n($$v))},expression:"form.minTotalDeposit"}})],1)],1)]}}],null,false,903556502)}):_vm._e(),(_vm.form.bankUseType === _vm.BankUseTypeEnum.BOTH || _vm.form.bankUseType === _vm.BankUseTypeEnum.DEPOSIT)?_c('ValidationProvider',{attrs:{"name":_vm.$t('bank_accounts.bank'),"rules":"required|between:0,100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ค่าธรรมเนียม","label-cols-lg":"3","label-cols-sm":"4","label-for":"multiplier-fee"}},[_c('b-input-group',{attrs:{"append":"%"}},[_c('b-input',{attrs:{"id":"multiplier-fee","state":errors[0] ? false : null,"step":"0.01","type":"number"},model:{value:(_vm.form.multiplierFee),callback:function ($$v) {_vm.$set(_vm.form, "multiplierFee", _vm._n($$v))},expression:"form.multiplierFee"}})],1)],1)]}}],null,false,2451855935)}):_vm._e(),([_vm.BankUseTypeEnum.BOTH, _vm.BankUseTypeEnum.DEPOSIT].includes(_vm.form.bankUseType) && !_vm.isAgent)?_c('b-form-group',{attrs:{"label-cols-lg":"3","label-cols-sm":"4","label-for":"showing_time"}},[_c('b-form-checkbox',{attrs:{"switch":true},model:{value:(_vm.form.isSetDisplayTime),callback:function ($$v) {_vm.$set(_vm.form, "isSetDisplayTime", $$v)},expression:"form.isSetDisplayTime"}},[_vm._v(" แสดงบัญชีตามเวลา "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"title":"แสดงบัญชีนี้ที่หน้าเว็บ เมื่อถึงเวลาที่กำหนด"}},[_c('b-icon-info-circle')],1)]),(_vm.form.isSetDisplayTime)?_c('b-row',{staticClass:"mt-3"},[_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required|min:5","name":"displayFrom"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"เริ่มเวลา"}},[_c('b-form-timepicker',{attrs:{"hour12":false,"state":errors[0] ? false : null,"locale":"en"},model:{value:(_vm.form.displayFrom),callback:function ($$v) {_vm.$set(_vm.form, "displayFrom", $$v)},expression:"form.displayFrom"}})],1)]}}],null,false,4104630324)})],1),_c('b-col',[_c('ValidationProvider',{attrs:{"rules":"required|min:5","name":"displayTo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"ถึงเวลา"}},[_c('b-form-timepicker',{attrs:{"hour12":false,"state":errors[0] ? false : null,"locale":"en"},model:{value:(_vm.form.displayTo),callback:function ($$v) {_vm.$set(_vm.form, "displayTo", $$v)},expression:"form.displayTo"}})],1)]}}],null,false,3936195680)})],1)],1):_vm._e()],1):_vm._e(),([_vm.BankUseTypeEnum.BOTH, _vm.BankUseTypeEnum.WITHDRAW].includes(_vm.form.bankUseType))?_c('b-form-group',{attrs:{"label-cols-lg":"3","label-cols-sm":"4","label-for":"isRequiredOtp"}},[_c('b-input-group',[_c('b-form-checkbox',{attrs:{"name":"isRequiredOtp","switch":""},model:{value:(_vm.form.isRequiredOtp),callback:function ($$v) {_vm.$set(_vm.form, "isRequiredOtp", $$v)},expression:"form.isRequiredOtp"}},[_vm._v(" เปิดใช้งาน OTP ถอน "),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",modifiers:{"hover":true,"html":true}}],attrs:{"title":"ส่ง OTP สำหรับถอนเข้าไลน์กลุ่ม (ตั้งค่าได้ที่เมนู <a href=\"/line-notifications\"><b><u>แจ้งเตือนไลน์</u></b>)"}},[_c('b-icon-info-circle')],1)])],1)],1):_vm._e(),_c('b-form-group',{staticClass:"m-0",attrs:{"label-cols-lg":"3","label-cols-sm":"4"}},[_c('b-form-checkbox',{attrs:{"id":"status","name":"status","switch":""},model:{value:(_vm.form.isEnable),callback:function ($$v) {_vm.$set(_vm.form, "isEnable", $$v)},expression:"form.isEnable"}},[_vm._v(" เปิดใช้งาน "),(_vm.form.bankUseType === _vm.BankUseTypeEnum.BOTH || _vm.form.bankUseType === _vm.BankUseTypeEnum.DEPOSIT)?_c('span',[_vm._v(" (แสดงหน้าเว็บ) ")]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }